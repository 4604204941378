// ** Redux Imports
import { createSlice, createAsyncThunk, current, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from '../../shared/authentication/AxiosInterceptor'
import { CustomMasterData } from '../models/CustomMasterData'

// ** Axios Imports

export const getCustomMaster = createAsyncThunk('customMaster/getCustomMaster', async (id) => {
    const response = await axiosInstance.get(`custom-master/${id}`)
    return response.data
})

export const configuration = createAsyncThunk('customMaster/configuration', async (id) => {
    const response = await axiosInstance.get(`/app-configuration`)
    return response.data
})


export const getOption = createAsyncThunk('customMaster/getOption', async (name) => {
    const response = await axiosInstance.get(`custom-master/option/${name}`)
    return response.data
})

export const getCustomMasterList = createAsyncThunk('customMaster/getCustomMasterList', async () => {
    const response = await axiosInstance.get('custom-master')
    return response.data
})

export const fetchWalletLaw = createAsyncThunk('fetchWalletLawData', async () => {
    const response =  await axiosInstance.get('/app/customer/get-wallet-law')
    return response.data
})

export const updateWalletLaw = createAsyncThunk('updateWalletLaw', async (data) => {
    const response = await axiosInstance.post('/customer/update-wallet-law', data)
    return response.data
})

export const addCustomMaster = createAsyncThunk('customMaster/addCustomMaster', async (data) => {
    const response = await axiosInstance.post('custom-master', data)
    return response.data
})

export const editConfig = createAsyncThunk('customMaster/editConfig', async (data) => {
    const response = await axiosInstance.put('/app-configuration', data)
    return response.data
})


export const deleteCustomMasterItem = createAsyncThunk('customMaster/deleteCustomMasterItem', async (id) => {
    const response = await axiosInstance.delete(`custom-master/item/${id}`)
    return response.data
})

export const configurePaymentGateway = createAsyncThunk('transaction/paymentGateway', async (data) => {
    const response = await axiosInstance.post(`transaction/paymentGateway`, data)
    return response.data
})

const initialState = {
    customMaster: [CustomMasterData]
}

export const customMasterSlice = createSlice({
    name: 'customMaster',
    initialState,
    reducers: {

        setCustomerMasterList: (state, action) => {
            Object.assign(state.customMaster, action.payload)
        },
        addCustomMasterItem: (state, action) => {
            let newList = state.customMaster.map((obj) => {
                if (obj.id === action.payload.id) {
                    return { ...obj, ...action.payload }
                } else {
                    return { ...obj }
                }
            })
            Object.assign(state.customMaster, newList)
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCustomMasterList.fulfilled, (state, action) => {
                let newList = action.payload.map((obj) => {
                    let temp = obj
                    temp.customMasterItems = temp.customMasterItems.sort((a, b) => {
                        var textA = a.name.toUpperCase()
                        var textB = b.name.toUpperCase()
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                    })
                    return temp
                })
                Object.assign(state.customMaster, newList)

            })
            .addCase(addCustomMaster.fulfilled, (state, action) => {
                let newList = state.customMaster.map((obj) => {
                    let temp
                    if (obj.id === action.payload.id) {
                        temp = { ...obj, ...action.payload }
                    } else {
                        temp = { ...obj }
                    }
                    temp.customMasterItems = temp.customMasterItems.sort((a, b) => {
                        var textA = a.name.toUpperCase()
                        var textB = b.name.toUpperCase()
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                    })
                    return temp
                })
                Object.assign(state.customMaster, newList)
            })
    }
})

export const { setCustomerMasterList, addCustomMasterItem } = customMasterSlice.actions


export default customMasterSlice.reducer
